<template>
  <k-card :class="{'h-100': isVisible}">
    <div
      v-b-toggle.collapse_note_summary_form
      class="card-header-container"
    >
      <p
        class="
          w-100
          font-poppins
          card-header-text
          text-start
          d-flex
          justify-content-between
        "
      >
        <span>Note Summary</span>
        <span><edit-summary-collapse-icon
          :class="isVisible ? 'top-arrow' : 'down-arrow'"
        /></span>
      </p>
    </div>
    <b-collapse
      id="collapse_note_summary_form"
      v-model="isVisible"
      class="card-body-container"
    >
      <div class="w-100 d-flex flex-column p-2">
        <div class="row">
          <div class="col-12 p-0">
            <k-form-group
              class="m-1"
              label-class="font-work-sans"
            >
              <template #label>
                Type <span class="invalid-input">*</span>
              </template>
              <div class="row p-0">
                <div class="col-6 pe-1">
                  <k-button
                    :variant="
                      createOrEditNote.type === NOTE_TYPES.CREDIT
                        ? 'secondary'
                        : 'outline-secondary'
                    "
                    block
                    :disabled="isEditNote"
                    @click="createOrEditNote.type = NOTE_TYPES.CREDIT"
                  >
                    {{ toTitleCase(NOTE_TYPES.CREDIT) }}
                  </k-button>
                </div>
                <div class="col-6 ps-1">
                  <k-button
                    :variant="
                      createOrEditNote.type === NOTE_TYPES.DEBIT
                        ? 'secondary'
                        : 'outline-secondary'
                    "
                    block
                    :disabled="isEditNote"
                    @click="createOrEditNote.type = NOTE_TYPES.DEBIT"
                  >
                    {{ toTitleCase(NOTE_TYPES.DEBIT) }}
                  </k-button>
                </div>
              </div>
            </k-form-group>
          </div>
          <div class="col-12 p-0">
            <k-form-group
              class="m-1"
              label-content="Note ID"
              label-class="font-work-sans"
            >
              <k-form-input
                v-model="createOrEditNote.noteId"
                type="text"
                placeholder="Note ID"
                disabled
              />
            </k-form-group>
          </div>
          <div class="col-12 p-0">
            <k-form-group
              class="m-1"
              label-content="Status"
              label-class="font-work-sans"
            >
              <b-dropdown
                class="w-100 k-search-dropdown dropdown-custom-class"
                :class="{ 'has-value': createOrEditNote.status }"
                variant="none"
                toggle-class="drop-caret-icon"
                :text="toTitleCase(createOrEditNote.status) || 'Select a Status'"
                disabled
              >
                <b-dropdown-item
                  v-for="option in Object.values(CREDIT_DEBIT_NOTE_STATUS)"
                  :key="option"
                  :active="createOrEditNote.status === option"
                  @click="createOrEditNote.status = option"
                >
                  <span class="text-capitalize">
                    {{ toTitleCase(option) }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </k-form-group>
          </div>
          <div class="col-12 p-0">
            <k-form-group
              class="m-1"
              label-class="font-work-sans"
            >
              <template #label>
                Date Issued <span class="invalid-input">*</span>
              </template>
              <k-form-input
                v-model="createOrEditNote.date"
                type="date"
                placeholder="Date Issued"
                :disabled="isDisableDate"
              />
            </k-form-group>
          </div>
          <div
            v-if="createOrEditNote.type===NOTE_TYPES.DEBIT"
            class="col-12 p-0"
          >
            <k-form-group
              class="m-1"
              label-class="font-work-sans"
              label-content="Due Date"
            >
              <k-form-input
                :value="formattedDate(createOrEditNote.dueDate)"
                type="text"
                placeholder="Due Date"
                disabled
              />
            </k-form-group>
          </div>
          <div class="col-12 p-0">
            <k-form-group
              class="m-1"
              label-content="Comments"
              label-class="font-work-sans"
            >
              <b-form-textarea
                v-model="createOrEditNote.comments"
                placeholder="Type in a comment related to this note"
                rows="3"
                :disabled="!createOrEditNote.isAccess"
              />
            </k-form-group>
          </div>
        </div>
      </div>
    </b-collapse>
  </k-card>
</template>

<script>
import { VBToggle, BCollapse, BFormTextarea } from 'bootstrap-vue'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import {
  KFormGroup, KFormInput, KButton, KCard,
} from '@kingpin-global/kingpin-ui'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import { toTitleCase } from '@/common-utils'
import { mapState } from 'vuex'
import { formatDateForInput, formattedDate } from '@/@core/utils/format'

const {
  NOTE_TYPES,
  CREDIT_DEBIT_NOTE_STATUS,
} = constants

export default {
  name: 'NoteSummaryForm',
  components: {
    BCollapse,
    EditSummaryCollapseIcon,
    BFormTextarea,
    KFormGroup,
    KFormInput,
    KButton,
    KCard,
  },
  directives: {
    'v-b-toggle': VBToggle,
  },
  props: {
    isEditNote: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: true,
      CREDIT_DEBIT_NOTE_STATUS,
      NOTE_TYPES,
      toTitleCase,
      formattedDate,
    }
  },
  computed: {
    ...mapState({
      createOrEditNote: state => state.notes.createOrEditNote,
    }),
    isDisableDate() {
      return (this.isEditNote && [CREDIT_DEBIT_NOTE_STATUS.APPLIED, CREDIT_DEBIT_NOTE_STATUS.PARITALLY_APPLIED].includes(this.createOrEditNote.status)) || !this.createOrEditNote.isAccess
    },
  },
  created() {
    this.createOrEditNote.date = this.createOrEditNote.date ? formatDateForInput(formattedDate(this.createOrEditNote.date)) : ''
  },
}
</script>
