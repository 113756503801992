<template>
  <k-card>
    <div
      v-b-toggle.collapse_note_financials_form
      class="card-header-container"
    >
      <p
        class="
          w-100
          font-poppins
          card-header-text
          text-start
          d-flex
          justify-content-between
        "
      >
        <span>Financials</span>
        <span><edit-summary-collapse-icon
          :class="isVisible ? 'top-arrow' : 'down-arrow'"
        /></span>
      </p>
    </div>
    <b-collapse
      id="collapse_note_financials_form"
      v-model="isVisible"
      class="card-body-container"
    >
      <div class="w-100 d-flex flex-column p-2">
        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="col-6 p-0">
                <k-form-group
                  class="m-1"
                  label-class="font-work-sans"
                  label-content="Currency"
                >
                  <b-dropdown
                    class="w-100 k-search-dropdown dropdown-custom-class"
                    :class="{ 'has-value': createOrEditNote.currency }"
                    variant="none"
                    toggle-class="drop-caret-icon"
                    :text="createOrEditNote.currency || 'Select a currency'"
                    disabled
                  >
                    <b-dropdown-item
                      v-for="option in Object.values(CURRENCY_CONVERSIONS).map(currency => currency.LABEL)"
                      :key="option"
                      :active="createOrEditNote.currency === option"
                      @click="createOrEditNote.currency = option"
                    >
                      <span class="text-capitalize">
                        {{ option }}
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </k-form-group>
              </div>
              <div class="col-6 p-0">
                <k-form-group
                  class="m-1"
                  label-class="font-work-sans"
                  label-content="VAT (%)"
                >
                  <k-form-input
                    v-model="createOrEditNote.vatPercentage"
                    type="number"
                    placeholder="VAT (%)"
                    :disabled="!createOrEditNote.isVatEligible || isEditNote"
                  />
                </k-form-group>
              </div>
            </div>
          </div>

          <!-- Description form list -->
          <div class="col-12 p-1 mt-2">
            <financial-description-form :is-edit-note="isEditNote" />
          </div>
          <!-- Description form list -->
          <div class="col-12 p-1 mt-2 font-inter">
            <div class="w-25 d-flex flex-column float-end total-container">
              <div class="d-flex flex-row mb-2">
                <div class="w-50 total-key-text text-start">
                  Subtotal:
                </div>
                <div class="w-50 total-value-text text-end">
                  {{ getSubtotal() || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row subtotal pb-2">
                <div class="w-50 total-key-text text-start">
                  VAT:
                </div>
                <div class="w-50 total-value-text text-end">
                  {{ getVatValue() || '-' }}
                </div>
              </div>
              <div class="d-flex flex-row mt-2">
                <div class="w-50 total-key-text text-start">
                  Total:
                </div>
                <div class="w-50 total-value-text text-end text-secondary">
                  {{ getTotal() || '-' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </k-card>
</template>

<script>
import { VBToggle, BCollapse } from 'bootstrap-vue'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import { KFormGroup, KFormInput, KCard } from '@kingpin-global/kingpin-ui'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import { mapState } from 'vuex'
import FinancialDescriptionForm from './FinancialDescriptionForm.vue'
import { CreateNoteMixin } from './create-note.mixin'

const { CURRENCY_CONVERSIONS } = constants

export default {
  name: 'FinancialsForm',
  components: {
    BCollapse,
    EditSummaryCollapseIcon,
    KFormGroup,
    KFormInput,
    KCard,
    FinancialDescriptionForm,
  },
  directives: {
    'v-b-toggle': VBToggle,
  },
  mixins: [CreateNoteMixin],
  props: {
    isEditNote: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: true,
      CURRENCY_CONVERSIONS,
    }
  },
  computed: {
    ...mapState({
      createOrEditNote: state => state.notes.createOrEditNote,
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "../notes-style/financials-form.scss";
</style>
