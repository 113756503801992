<template>
  <div>
    <!-- Header -->
    <k-header-card class="d-flex justify-content-between">
      <div
        class="w-50 d-flex justify-content-center align-items-start flex-column"
      >
        <span
          class="text-primary d-flex align-items-center cursor-pointer"
          @click="$router.go(-1)"
        >
          <feather-icon
            class="me-1"
            icon="ChevronLeftIcon"
          /> Back To Notes
        </span>
        <div
          v-if="createOrEditNote.noteId"
          class="d-flex flex-column col-sm-12 col-lg-6 text-start"
        >
          <div>
            <span class="kp-text-color">Note ID:</span>
            <span class="kp-text-color ms-1">{{ createOrEditNote.noteId }}</span>
          </div>
        </div>
      </div>
      <!-- Action Button -->
      <div
        v-if="isSuperAdmin"
        class="w-25 d-flex justify-content-end align-items-center"
      >
        <k-button
          variant="outline-info"
          class="px-5"
          :disabled="isCreatingOrUpdating"
          @click="resetNote"
        >
          CANCEL
        </k-button>
        <k-button
          class="ms-3 text-nowrap"
          variant="info"
          :disabled="isCreatingOrUpdating"
          @click="onCreateOrUpdateNote"
        >
          <loader-icon
            v-if="isCreatingOrUpdating"
            class="me-1"
          />
          <span>{{ isEditNote ? 'SAVE' : 'CREATE NOTE' }}</span>
        </k-button>
      </div>
    </k-header-card>

    <!-- Body -->
    <div class="kp-body-container">
      <div
        v-if="isEditNote && isLoadingGetNote"
        class="spinner-body"
      >
        <b-spinner />
      </div>
      <div v-else>
        <div>
          <note-card-details />
        </div>
        <div class="mt-3 row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-6 col-xs-12 pe-lg-2">
                <note-summary-form
                  :key="updateKey"
                  :is-edit-note="isEditNote"
                />
              </div>
              <div class="col-lg-6 col-xs-12 ps-lg-2 mt-3 mt-lg-0">
                <note-details-form
                  :is-edit-note="isEditNote"
                  @updateSummaryData="updateSummaryData"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mt-3">
            <financials-form :is-edit-note="isEditNote" />
          </div>
          <div class="col-12 mt-3">
            <financial-documents />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KHeaderCard from '@/@core/components/KHeaderCard.vue'
import FeatherIcon from '@/components/feather-icon/FeatherIcon.vue'
import { KButton } from '@kingpin-global/kingpin-ui'
import {
  CREATE_NOTE, GET_NOTE, RESET_NOTE, SET_NOTE, UPDATE_NOTE,
} from '@/store/modules/notes.module'
import { apiToastErrorV2, apiToastSuccess } from '@/@core/utils/toast'
import { ROUTES } from '@/constants'
import { LoaderIcon } from 'vue-feather-icons'
import { mapState } from 'vuex'
import { FETCH_SHIPMENTS } from '@/store/modules/shipment.module'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import NoteCardDetails from './NoteCardDetails.vue'
import NoteSummaryForm from './NoteSummaryForm.vue'
import NoteDetailsForm from './NoteDetailsForm.vue'
import FinancialsForm from './FinancialsForm.vue'
import FinancialDocuments from './FinancialDocuments.vue'
import { getPayloadCreateNote, getPayloadUpdateNote } from '../notes-utils'

const { CURRENCY_CONVERSIONS } = constants

export default {
  name: 'CreateNote',
  components: {
    NoteCardDetails,
    KHeaderCard,
    KButton,
    FeatherIcon,
    NoteSummaryForm,
    NoteDetailsForm,
    FinancialsForm,
    FinancialDocuments,
    LoaderIcon,
  },
  data() {
    return {
      isCreatingOrUpdating: false,
      noteObjId: null,
      isLoadingGetNote: false,
      updateKey: 1,
    }
  },
  computed: {
    ...mapState({
      createOrEditNote: state => state.notes.createOrEditNote,
    }),
    isSuperAdmin() {
      return this.$store.getters.isSuperAdmin
    },
    isEditNote() {
      return !!this.noteObjId
    },
  },
  created() {
    if (this.$route.params.id) {
      this.noteObjId = this.$route.params.id
      this.getNoteDetaiils()
    } else {
      this.resetNote()
    }
  },
  destroyed() {
    this.resetNote()
  },
  methods: {
    getNoteDetaiils() {
      this.isLoadingGetNote = true
      this.$store.dispatch(GET_NOTE, this.noteObjId).then(res => {
        const noteDetails = res.data.data.note
        this.$store.commit(SET_NOTE, noteDetails)
        this.getShipmentData(noteDetails.shipmentSeqId)
      })
        .catch(err => {
          this.isLoadingGetNote = false
          apiToastErrorV2(err)
        })
    },
    // Functions used for Create Note
    resetNote() {
      if (this.isEditNote) {
        this.getNoteDetaiils()
      } else {
        this.$store.commit(RESET_NOTE)
      }
    },
    setFinancials() {
      this.createOrEditNote.financials = this.createOrEditNote.financials.map(_ => ({
        description: _.description,
        quantity: parseInt(_.quantity || 0),
        currency: this.createOrEditNote.currency,
        amount: parseFloat(_.amount || 0), // Unit price
      }))
    },
    async onCreateOrUpdateNote() {
      this.isCreatingOrUpdating = true
      let result
      try {
        if (this.isEditNote) {
          const payload = getPayloadUpdateNote(this.createOrEditNote)
          if (!payload) { return }
          result = await this.$store.dispatch(UPDATE_NOTE, { id: this.noteObjId, payload })
          apiToastSuccess(result?.data?.message)
        } else {
          this.setFinancials()
          const payload = getPayloadCreateNote(this.createOrEditNote)
          if (!payload) { return }
          result = await this.$store.dispatch(CREATE_NOTE, payload)
          apiToastSuccess(result.data.message)
          this.resetNote()
          this.$router.push({ path: ROUTES.NOTES.path })
        }
      } catch (err) {
        apiToastErrorV2(err)
      } finally {
        this.isCreatingOrUpdating = false
      }
    },

    getShipmentData(shipmentSeqId) {
      const queryParams = {
        search: shipmentSeqId,
      }
      this.$store.dispatch(FETCH_SHIPMENTS, queryParams).then(res => {
        const shipment = res.data.data.shipments[0]
        this.createOrEditNote.shipmentSeqId = shipment?.shipmentSeqId || shipment?._id || ''
        this.createOrEditNote.isVatEligible = shipment?.isVatEligible || false
        this.createOrEditNote.vatPercentage = this.createOrEditNote.vatPercentage || parseInt((shipment?.vatPercentage) || 0)
        this.createOrEditNote.currency = CURRENCY_CONVERSIONS.USD.LABEL
        this.isLoadingGetNote = false
      })
    },
    updateSummaryData() {
      this.updateKey++
    },
  },
}
</script>

<style lang="scss">
@import "@/@core/scss/vue/pages/kp-details-tab.scss";
</style>
