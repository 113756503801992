<template>
  <k-card>
    <div class="row notes-card-container">
      <!-- Note Summary -->
      <div class="col-6 card-section">
        <div class="font-inter text-start text-secondary card-header-text">
          Note Summary
        </div>
        <div>
          <div
            v-for="item of noteSummaryData"
            :key="item.label"
            class="d-flex justify-content-between label-value-container"
          >
            <div class="label-text">
              {{ item.label }}:
            </div>
            <div
              class="value-text"
              :class="item.class"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>

      <!-- Note Details -->
      <div class="col-6 card-section">
        <div class="font-inter text-start text-secondary card-header-text">
          Note Details
        </div>
        <div>
          <div
            v-for="item of noteDetailsData"
            :key="item.label"
            class="d-flex justify-content-between label-value-container"
          >
            <div class="label-text">
              {{ item.label }}:
            </div>
            <div v-if="!!item.route && createOrEditNote._id">
              <b-link
                class="text-primary text-decoration-none"
                :to="{name: item.route.name, params : item.route.params }"
              >
                {{ item.value }}
              </b-link>
            </div>
            <div
              v-else
              class="value-text"
              :class="{ 'text-primary' : !!item.route }"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </k-card>
</template>

<script>
import { formattedDate } from '@/@core/utils/format'
import { toTitleCase } from '@/common-utils'
import { ROUTES } from '@/constants'
import { KCard } from '@kingpin-global/kingpin-ui'
import { mapState } from 'vuex'
import { CreateNoteMixin } from './create-note.mixin'

export default {
  name: 'NoteCardDetails',
  components: {
    KCard,
  },
  mixins: [CreateNoteMixin],
  computed: {
    ...mapState({
      createOrEditNote: state => state.notes.createOrEditNote,
    }),
    noteSummaryData() {
      return [
        { label: 'Type', value: toTitleCase(this.createOrEditNote.type) },
        { label: 'Note ID', value: this.createOrEditNote.noteId },
        { label: 'Status', value: toTitleCase(this.createOrEditNote.status) },
        { label: 'Date Issued', value: formattedDate(this.createOrEditNote.date, '-') },
      ]
    },
    noteDetailsData() {
      return [
        { label: 'Account', value: this.createOrEditNote.entityName, route: { name: ROUTES.USERS.USER_DETAILS.name, params: { id: this.createOrEditNote.userId } } },
        { label: 'Reference Shipment Id', value: this.createOrEditNote.shipmentSeqId, route: { name: ROUTES.SHIPMENT_MANAGEMENT.CHILDREN.SHIPMENT_DETAIL_PAGE.name, params: { shipmentId: this.createOrEditNote.shipmentId } } },
        { label: 'Shipment Applied To', value: this.createOrEditNote.associatedNote },
        { label: 'Amount Remaining with VAT', value: this.getTotal() || '' },
        { label: 'Amount with VAT', value: this.getTotal() || '' },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../notes-style/note-card-details.scss";
</style>
