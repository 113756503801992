import { utils } from '@kingpin-global/kingpin-utils-frontend'

export const CreateNoteMixin = {
  methods: {
    getSubtotal() {
      return this.createOrEditNote.financials.map(item => (item.quantity * item.amount) || 0).reduce((a, b) => a + b, 0)
    },
    getVatValue() {
      return utils.round(
        this.getSubtotal(),
        utils.convertPercentToFraction(this.createOrEditNote.vatPercentage || 0),
      )
    },
    getTotal() {
      return this.getSubtotal() + this.getVatValue()
    },
  },
}
