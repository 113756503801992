<template>
  <k-card>
    <div
      v-b-toggle.collapse_fn_documents
      class="card-header-container"
    >
      <p
        class="
          w-100
          font-poppins
          card-header-text
          text-start
          d-flex
          justify-content-between
        "
      >
        <span> Documents </span>
        <span>
          <edit-summary-collapse-icon
            :class="isVisible ? 'top-arrow' : 'down-arrow'"
          />
        </span>
      </p>
    </div>
    <b-collapse
      id="collapse_fn_documents"
      v-model="isVisible"
      class="card-body-container"
    >
      <div class="mb-2">
        <div class="content cursor-pointer">
          <span
            class="d-flex align-items-center"
            @click="generatedCollapse = !generatedCollapse"
          >
            <caret-svg-icon :class="generatedCollapse ? 'down' : 'right'" />
            <span class="address-label font-poppins"> Generated </span>
          </span>
        </div>
        <b-collapse
          id="generated_docs"
          :visible="generatedCollapse"
        >
          <div
            v-if="createOrEditNote._id"
            class="text-start"
          >
            <file-item
              :file-name="`${createOrEditNote.noteId}.pdf`"
              file-type="pdf"
              hide-delete
              :hide-download="!createOrEditNote._id"
              @on-download="downloadGeneratedNoteInvoice()"
            />
          </div>
          <div
            v-else
            class="text-start"
          >
            <small class="ms-3">No documents are there.</small>
          </div>
        </b-collapse>
      </div>
      <div class="mb-2">
        <div class="content cursor-pointer">
          <span
            class="d-flex align-items-center"
            @click="uploadedCollapse = !uploadedCollapse"
          >
            <caret-svg-icon :class="uploadedCollapse ? 'down' : 'right'" />
            <span class="address-label font-poppins"> Uploaded </span>
          </span>
        </div>
        <b-collapse
          id="uploaded_docs"
          :visible="uploadedCollapse"
        >
          <div
            v-if="createOrEditNote.attachments && createOrEditNote.attachments.length"
            class="text-start"
          >
            <file-item
              v-for="(attachment, i) in createOrEditNote.attachments"
              :key="attachment"
              :file-name="`Attachment${i + 1}.${getFileNameAndExtension(attachment).extension}`"
              :file-type="getFileNameAndExtension(attachment).extension"
              :hide-delete="!createOrEditNote._id || !createOrEditNote.isAccess"
              :hide-download="!createOrEditNote._id"
              @on-download="downloadAttachment(attachment, `Attachment${i + 1}.${getFileNameAndExtension(attachment).extension}`)"
              @on-delete="deleteAttachment(attachment, i)"
            />
          </div>
          <div
            v-else
            class="text-start"
          >
            <small class="ms-3">No documents are there.</small>
          </div>
        </b-collapse>
      </div>
    </b-collapse>
  </k-card>
</template>
<script>
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import CaretSvgIcon from '@/assets/images/svg/CaretSvgIcon.vue'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import { download, getFileNameAndExtension } from '@/common-utils'
import FileItem from '@/components/file-item/FileItem.vue'
import { FILE_FORMATS } from '@/constants'
import { DOWNLOAD_FILE } from '@/store/modules/common.module'
import { DELETE_NOTE_ATTACHMENT } from '@/store/modules/notes.module'
import { KCard } from '@kingpin-global/kingpin-ui'
import { BCollapse, VBToggle } from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  name: 'DocumentsCard',
  components: {
    BCollapse,
    EditSummaryCollapseIcon,
    CaretSvgIcon,
    FileItem,
    KCard,
  },
  directive: {
    'v-b-toggle': VBToggle,
  },
  data() {
    return {
      isVisible: true,
      generatedCollapse: true,
      uploadedCollapse: true,
      getFileNameAndExtension,
    }
  },
  computed: {
    ...mapState({
      createOrEditNote: state => state.notes.createOrEditNote,
    }),
  },
  methods: {
    downloadGeneratedNoteInvoice() {
      const params = {
        isAedTableIncluded: this.createOrEditNote?.includeAedEquivalent || false,
      }
      try {
        const payload = {
          url: this.$kpParams(this.$kpEndpoint.creditAndDebit.downloadInvoice, this.createOrEditNote._id),
          params,
          fileFormat: FILE_FORMATS.PDF,
          successMessage: `Your Invoice is downloaded for the note ${this.createOrEditNote.noteId}`,
          fileName: this.createOrEditNote.noteId,
          headers: {
            responseType: 'blob',
            headers: {
              Accept: 'application/pdf',
            },
          },
        }
        this.$store.dispatch(DOWNLOAD_FILE, payload)
      } catch (err) {
        apiToastError(err)
      }
    },
    downloadAttachment(url, name) {
      download(url, name)
    },
    deleteAttachment(url, i) {
      this.$store.dispatch(DELETE_NOTE_ATTACHMENT, { id: this.createOrEditNote._id, url })
        .then(res => {
          this.createOrEditNote.attachments.splice(i, 1)
          apiToastSuccess(res.data.message)
        })
        .catch(err => {
          apiToastError(err)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/@core/scss/vue/pages/kp-document-list.scss";
</style>
