<template>
  <div class="w-100">
    <div
      class="
        d-flex
        flex-column
        text-start
        font-inter
        fn-table-content
        overflow-auto
      "
    >
      <div class="d-flex flex-row header-row">
        <div class="description">
          <span> Description <span class="invalid-input">*</span></span>
        </div>
        <div class="qty">
          <span> Quantity <span class="invalid-input">*</span></span>
        </div>
        <div class="unit-price">
          <span> Unit Price w/o VAT <span class="invalid-input">*</span></span>
        </div>
        <div class="amount">
          <span> Amount w/o VAT </span>
        </div>
      </div>
      <div
        v-for="(item, index) in createOrEditNote.financials"
        :key="'description' + index"
        class="d-flex flex-row body-row"
      >
        <div class="description fn-col">
          <k-form-group class="fn-table-input">
            <k-form-input
              v-model="item.description"
              type="text"
              :disabled="isEditNote"
            />
          </k-form-group>
        </div>
        <div class="qty fn-col">
          <k-form-group class="fn-table-input">
            <k-form-input
              v-model="item.quantity"
              type="number"
              :disabled="isEditNote"
            />
          </k-form-group>
        </div>
        <div class="unit-price fn-col">
          <k-form-group class="fn-table-input">
            <k-form-input
              v-model="item.amount"
              type="number"
              :disabled="isEditNote"
            />
          </k-form-group>
        </div>
        <div class="amount fn-col">
          <k-form-group class="fn-table-input">
            <k-form-input
              :value="getLineAmountWithVat(item) || null"
              type="number"
              disabled
            />
          </k-form-group>
        </div>
      </div>
    </div>
    <div class="text-start mt-3">
      <k-button
        class="text-nowrap"
        variant="outline-secondary"
        :disabled="isEditNote"
        @click="onClickAddLine"
      >
        Add a new line
      </k-button>
    </div>
    <div class="text-start mt-3">
      <div class="d-flex justify-content-between">
        <label class="custom-circle-checkbox-container kp-text-color">
          Include AED Equivalent conversion in the generated PDF file.
          <input
            type="checkbox"
            @change="onIncludeAED"
          >
          <span class="checkmark" />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { KFormGroup, KFormInput, KButton } from '@kingpin-global/kingpin-ui'
import { utils } from '@kingpin-global/kingpin-utils-frontend'
import { mapState } from 'vuex'
import { getFinancialItem } from '../notes-utils'

export default {
  name: 'FinancialDescriptionForm',
  components: {
    KFormGroup,
    KFormInput,
    KButton,
  },
  props: {
    isEditNote: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      createOrEditNote: state => state.notes.createOrEditNote,
    }),
  },
  emits: ['on-calculate-total'],
  methods: {
    onClickAddLine() {
      this.createOrEditNote.financials.push(getFinancialItem(this.createOrEditNote.currency))
    },
    onIncludeAED(e) {
      this.createOrEditNote.includeAedEquivalent = e.target.checked
    },
    getLineAmountWithVat(item) {
      const amount = item.quantity * item.amount
      return amount + utils.round(
        amount,
        utils.convertPercentToFraction(this.createOrEditNote.vatPercentage || 0),
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../notes-style/financial-description-form.scss";
</style>
