<template>
  <k-card :class="{'h-100': isVisible}">
    <div
      v-b-toggle.collapse_note_details_form
      class="card-header-container"
    >
      <p
        class="
          w-100
          font-poppins
          card-header-text
          text-start
          d-flex
          justify-content-between
        "
      >
        <span>Note Details</span>
        <span><edit-summary-collapse-icon
          :class="isVisible ? 'top-arrow' : 'down-arrow'"
        /></span>
      </p>
    </div>
    <b-collapse
      id="collapse_note_details_form"
      v-model="isVisible"
      class="card-body-container"
    >
      <div class="w-100 d-flex flex-column p-2">
        <div class="row">
          <div class="col-12 p-0">
            <k-form-group
              class="m-1"
              label-class="font-work-sans"
            >
              <template #label>
                Reference Shipment Id <span class="invalid-input">*</span>
              </template>
              <v-select
                v-model="createOrEditNote.shipmentId"
                class="v-select-custom font-inter"
                :options="shipments"
                :reduce="(shipment) => shipment._id"
                label="shipmentSeqId"
                placeholder="Type the reference shipment id"
                :close-on-select="true"
                :autoscroll="true"
                :searchable="true"
                :loading="loadingShipments"
                :disabled="isEditNote"
                @search="onSearchShipment"
                @option:selected="onSelectedShipment"
                @input="onClearedShipmentId"
              >
                <template
                  slot="option"
                  slot-scope="shipment"
                >
                  <div class="d-flex flex-row align-items-center vs-option-item">
                    <span>{{ shipment.shipmentSeqId }}</span> &nbsp;<span class="user-name">{{ shipment.brandName }} - {{ shipment.retailerName }}</span>
                  </div>
                </template>
              </v-select>
            </k-form-group>
          </div>
          <div class="col-12 p-0">
            <k-form-group
              class="m-1"
              label-class="font-work-sans"
            >
              <template #label>
                Account <span class="invalid-input">*</span>
              </template>
              <v-select
                v-model="createOrEditNote.userId"
                class="v-select-custom font-inter"
                :options="shipmentUsers"
                :reduce="(user) => user.id"
                label="name"
                placeholder="Type the entity name"
                :close-on-select="true"
                :autoscroll="true"
                :searchable="true"
                :disabled="!shipmentUsers.length || isEditNote"
                @option:selected="onSelectedUser"
                @input="onClearedUserId"
              >
                <template
                  slot="option"
                  slot-scope="user"
                >
                  <div class="d-flex flex-row align-items-center vs-option-item">
                    <span class="text-capitalize">{{ user.name }} - {{ user.role }}</span>
                  </div>
                </template>
              </v-select>
            </k-form-group>
          </div>
          <div class="col-12 p-0">
            <k-form-group
              class="m-1"
              label-class="font-work-sans"
              label-content="Associated Note"
            >
              <k-form-input
                v-model="createOrEditNote.associatedNote"
                type="text"
                placeholder="Type the Associated Note id"
                :disabled="isEditNote"
              />
            </k-form-group>
          </div>
          <div class="col-12 p-0 upload-file-button">
            <k-form-group
              class="m-1"
              label-class="font-work-sans"
              label-content="Attachments"
            >
              <label
                for="upload_attachments"
                class="
                upload-files
                d-flex
                justify-content-center
                align-items-center
                text-primary
              "
              >
                <loader-icon v-if="isSavingFile" />
                <!-- upload icon -->
                <upload-icon v-else />
                <span class="button-text font-inter">
                  Upload files from your system
                </span>
              </label>

              <b-form-file
                v-if="!isSavingFile && createOrEditNote.isAccess"
                id="upload_attachments"
                v-model="uploadedFile"
                placeholder="Upload files from your system"
                drop-placeholder="Drop file here..."
                plain
                hidden="true"
              />
            </k-form-group>
          </div>
        </div>
      </div>
    </b-collapse>
  </k-card>
</template>

<script>
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import UploadIcon from '@/assets/images/svg/UploadIcon.vue'
import { getFileNameAndExtension, toTitleCase } from '@/common-utils'
import { UPLOAD_DOCUMENTS } from '@/store/modules/common.module'
import { FETCH_SHIPMENTS } from '@/store/modules/shipment.module'
import { FETCH_TRANSACTIONS } from '@/store/modules/transaction-module'
import { KCard, KFormGroup, KFormInput } from '@kingpin-global/kingpin-ui'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import { BCollapse, BFormFile, VBToggle } from 'bootstrap-vue'
import { LoaderIcon } from 'vue-feather-icons'
import { mapState } from 'vuex'

const { FILE_TYPES, ROLES, TRANSACTION_TYPE } = constants

export default {
  name: 'NoteDetailsForm',
  components: {
    BCollapse,
    EditSummaryCollapseIcon,
    BFormFile,
    KFormGroup,
    KFormInput,
    KCard,
    UploadIcon,
    LoaderIcon,
  },
  directives: {
    'v-b-toggle': VBToggle,
  },
  props: {
    isEditNote: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: true,
      shipments: [],
      loadingShipments: false,
      shipmentUsers: [],
      uploadedFile: null,
      isSavingFile: false,
      debounce: null,
    }
  },
  computed: {
    ...mapState({
      createOrEditNote: state => state.notes.createOrEditNote,
    }),
  },
  watch: {
    uploadedFile() {
      this.onUploadFile()
    },
  },
  created() {
    if (!this.isEditNote) {
      this.loadShipments()
    }
  },
  emits: ['updateSummaryData'],
  methods: {
    loadShipments(searchingText = '') {
      this.loadingShipments = true
      const limit = searchingText ? 50 : 10
      const queryParams = {
        page: 0,
        limit,
        asc: true,
        sortBy: 'shipmentSeqId',
        search: searchingText,
      }
      this.$store.dispatch(FETCH_SHIPMENTS, queryParams).then(res => {
        this.shipments = res.data.data.shipments
        this.loadingShipments = false
        if (this.shipments.length === 1) {
          this.onSelectedShipment(this.shipments[0])
        }
      })
        .catch(err => {
          apiToastError(err)
          this.loadingShipments = false
        })
    },
    onClearedShipmentId(input) {
      if (!input) {
        // reset shipment
        this.onSetShipmentData(null)
        this.shipmentUsers = []
        this.loadShipments()
      }
    },
    onClearedUserId(input) {
      if (!input) {
        this.createOrEditNote.entityName = ''
      }
    },
    onClearUser() {
      this.createOrEditNote.userId = ''
      this.createOrEditNote.entityName = ''
    },
    onSetShipmentData(shipment) {
      this.createOrEditNote.shipmentSeqId = shipment ? shipment.shipmentSeqId || shipment._id : ''
      this.createOrEditNote.isVatEligible = shipment ? shipment.isVatEligible : false
      this.createOrEditNote.vatPercentage = parseInt((shipment && shipment.vatPercentage) || 0)
      if (!this.isEditNote) {
        this.onClearUser()
      }
    },
    async onSelectedShipment(selectedShipment) {
      this.shipmentUsers = []
      const retailerObj = {
        id: selectedShipment.retailerId || '',
        name: toTitleCase(selectedShipment.retailerName) || '',
        role: ROLES.RETAILER,
      }
      this.shipmentUsers.push(retailerObj)
      const brandObj = {
        id: selectedShipment.brandId || '',
        name: toTitleCase(selectedShipment.brandName) || '',
        role: ROLES.BRAND,
      }
      const res = await this.$store.dispatch(FETCH_TRANSACTIONS,
        {
          shipmentId: selectedShipment._id,
          role: ROLES.RETAILER,
          type: TRANSACTION_TYPE.SHIPMENT,
        })
      const resData = res.data.data
      if (resData.data.length) {
        this.createOrEditNote.dueDate = resData.data[0].dueDate
        this.$emit('updateSummaryData')
      }
      this.shipmentUsers.push(brandObj)
      this.onSetShipmentData(selectedShipment)
    },
    onSelectedUser(user) {
      this.createOrEditNote.entityName = user.name || user.id
    },
    async onUploadFile() {
      this.isSavingFile = true
      if (this.uploadedFile instanceof File) {
        try {
          const { fileName, extension } = getFileNameAndExtension(this.uploadedFile.name)
          const formData = new FormData()
          formData.append('file', this.uploadedFile, `${fileName}.${extension}`)
          const fileLink = await this.$store.dispatch(UPLOAD_DOCUMENTS, { fileType: FILE_TYPES.CREDIT_DEBIT_NOTE_ATTACHMENT, payload: formData })
          apiToastSuccess('File attached successfully')
          this.createOrEditNote.attachments.push(fileLink)
          this.isSavingFile = false
        }
        catch (err) {
          apiToastError(err)
          this.isSaving = false
        }
      } else {
        apiToastError('File not supported')
        this.isSaving = false
      }
    },
    onSearchShipment(searchText) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.loadShipments(searchText)
      }, 500)
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/@core/scss/vue/pages/kp-document-list.scss";

.vs-option-item {
  .user-name {
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
  }
}
</style>
